<template>
    
  <div
    class="menu"
    v-loading.fullscreen.lock="loading"
    element-loading-text="拼命加载中"
    element-loading-background="rgba(255, 255, 255, 0.5)"
  >
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <!-- 消息通知 -->
      <el-tab-pane label="消息通知" name="messageInfo">
        <div>
          <el-select 
           clearable
           placeholder="消息类型" 
           v-model="pageInfo.messageType"
          >
            <el-option
              v-for="item in messageTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <TimeQuantum
            style="margin-right: 10px"
            @selectFinish="topTime"
            section="近7天"
            @sectionFinish="sectionSure"
          ></TimeQuantum>
          <el-input placeholder="请输入标题或正文内容" v-model="pageInfo.keyword"></el-input>
          <el-button type="primary" icon="el-icon-search" @click="search">立即查询</el-button>
          <el-button type="primary" @click="allread">标记已读</el-button>
          <!-- <el-button
            type="primary"
            icon="el-icon-circle-plus-outline"
            @click="add"
            >新增</el-button
          > -->
          <!-- 表格 -->
          <el-table
            :data="tableData"
            border
            cell-style="text-align:center"
            header-cell-style="text-align:center;background:#EBEEF5"
            style="width: 100%; margin-top: 40px"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              width="55"
              align="center"
              fixed
            ></el-table-column>
            <el-table-column
              prop="messageTitle"
              label="消息标题"
              width="width"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column
              prop="messageContent"
              label="消息正文"
              width="width"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column prop="messageType" label="消息类型" width="width">
              <template slot-scope="scope">
                <span
                  v-if="scope.row.messageType == '公告'"
                  style="color: #3cb371"
                  >{{ scope.row.messageType }}</span
                >
                <span
                  v-if="scope.row.messageType == '警告'"
                  style="color: #b22222"
                  >{{ scope.row.messageType }}</span
                >
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="发布时间" width="width">
            </el-table-column>
            <!-- <el-table-column label="发布状态" width="width">
              <template slot-scope="scoped">
                <div
                  style="
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    padding: 0 20px;
                  "
                >
                  <el-switch
                    style="display: block"
                    v-model="scoped.row.status"
                    active-text="发布"
                    inactive-text="保存"
                    :active-value="Number(1)"
                    :inactive-value="Number(0)"
                    @change="changeStatus(scoped.row.id, scoped.row.status)"
                  >
                  </el-switch>
                </div>
              </template>
            </el-table-column> -->
            <el-table-column prop="prop" label="操作" width="width">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  style="font-size: 15px"
                  class="el-icon-document"
                  @click="getDetail(scope.row.id)"
                  >详情</el-button
                >
                <!-- <el-button
                  type="text"
                  style="font-size: 15px"
                  @click="changeMessage(scope.row.id)"
                  >修改</el-button
                >
                <el-button
                  type="text"
                  style="font-size: 15px"
                  @click="deleteMessage(scope.row.id)"
                  >删除</el-button
                > -->
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页区域 -->
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :page-sizes="[10, 20, 30, 40]"
            :total="total"
            :page-size="pageInfo.pageSize"
            :current-page="pageInfo.current"
          >
          </el-pagination>
          <!-- 详情弹框 -->
          <el-dialog
            center
            width="900px"
            :title="title"
            :visible.sync="detailBox"
            :close-on-click-modal="false"
          >
            <el-form
              ref="formInline"
              :inline="true"
              :model="detailtableData"
              class="demo-form-inline"
              label-position="left"
              label-width="80px"
            >
              <el-form-item
                label="消息标题"
                prop="messageTitle"
                style="width: 700px"
              >
                <el-input
                  disabled
                  cols="72%"
                  type="textarea"
                  v-model="detailtableData.messageTitle"
                  :autosize="{ minRows: 1, maxRows: 5 }"
                  resize="none"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="消息正文"
                prop="messageContent"
                style="width: 700px"
              >
                <el-input
                  disabled
                  cols="72%"
                  type="textarea"
                  v-model="detailtableData.messageContent"
                  :autosize="{ minRows: 1, maxRows: 5 }"
                  resize="none"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="消息类型"
                prop="messageType"
                style="width: 800px"
              >
                <el-input
                  disabled
                  cols="72%"
                  type="textarea"
                  v-model="detailtableData.messageType"
                  :autosize="{ minRows: 1, maxRows: 5 }"
                  resize="none"
                ></el-input>
              </el-form-item>
              <el-form-item label="发布时间" prop="createTime">
                <el-input
                  disabled
                  cols="72%"
                  type="textarea"
                  v-model="detailtableData.createTime"
                  :autosize="{ minRows: 1, maxRows: 5 }"
                  resize="none"
                ></el-input>
              </el-form-item>
              <!-- <el-form-item label="发布状态" prop="status">
                <div style="width: 450px">
                  <el-switch
                    disabled
                    style="display: block"
                    v-model="detailtableData.status"
                    active-text="发布"
                    inactive-text="保存"
                    :active-value="Number(1)"
                    :inactive-value="Number(0)"
                  >
                  </el-switch>
                </div>
              </el-form-item> -->

              <div style="text-align: center; margin-top: 30px">
                <el-button @click="detailBox = false">取 消</el-button>
                <el-button type="primary" @click="detailBox = false">确认</el-button>
              </div>
            </el-form>
          </el-dialog>
          <!-- 新增弹框 -->
          <el-dialog width="960px" :title="title" :visible.sync="addBox">
            <el-form
              ref="formInline"
              :inline="true"
              :model="formInline"
              class="demo-form-inline"
              label-position="left"
              label-width="120px"
            >
              <el-form-item
                label="消息标题"
                prop="messageTitle"
                style="width: 700px"
              >
                <el-input
                  cols="72%"
                  type="textarea"
                  v-model="formInline.messageTitle"
                  :autosize="{ minRows: 1, maxRows: 5 }"
                  resize="none"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="消息正文"
                prop="messageContent"
                style="width: 700px"
              >
                <el-input
                  cols="72%"
                  type="textarea"
                  v-model="formInline.messageContent"
                  :autosize="{ minRows: 1, maxRows: 5 }"
                  resize="none"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="消息类型"
                prop="messageType"
                style="width: 800px"
              >
                <el-select
                  v-model="formInline.messageType"
                  placeholder="请选择"
                  style="margin-top: 0"
                >
                  <el-option
                    v-for="item in typelist"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="发布状态" prop="status">
                <el-switch
                  style="display: block"
                  v-model="formInline.status"
                  active-text="发布"
                  inactive-text="保存"
                  active-value="1"
                  inactive-value="0"
                >
                </el-switch>
              </el-form-item>
              <el-form-item
                label="定时发布时间"
                prop="status"
                v-if="formInline.status == 0"
              >
                <div class="block" style="width: 700px">
                  <el-date-picker
                    v-model="formInline.waitingReleaseTime"
                    type="datetime"
                    placeholder="选择日期时间"
                    value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
              <div style="text-align: right; margin-top: 30px">
                <el-button @click="addBox = false">取 消</el-button>
                <el-button type="primary" @click="messageconfirm"
                  >确定</el-button
                >
              </div>
            </el-form>
          </el-dialog>
          <!-- 编辑弹框 -->
          <el-dialog
            width="1140px"
            title="修改消息"
            :visible.sync="changeBox"
            :close-on-click-modal="false"
          >
            <el-form
              ref="formInline"
              :inline="true"
              :model="detailtableDatas"
              class="demo-form-inline"
              label-position="left"
              label-width="80px"
            >
              <el-form-item
                label="消息标题"
                prop="messageTitle"
                style="width: 700px"
              >
                <el-input
                  cols="72%"
                  type="textarea"
                  v-model="detailtableDatas.messageTitle"
                  :autosize="{ minRows: 1, maxRows: 5 }"
                  resize="none"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="消息正文"
                prop="messageContent"
                style="width: 700px"
              >
                <el-input
                  cols="72%"
                  type="textarea"
                  v-model="detailtableDatas.messageContent"
                  :autosize="{ minRows: 1, maxRows: 5 }"
                  resize="none"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="消息类型"
                prop="messageType"
                style="width: 800px"
              >
                <el-select
                  placeholder=""
                  v-model="detailtableDatas.messageType"
                  style="margin: 0"
                >
                  <el-option
                    v-for="item in messageTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="发布状态" prop="status">
                <div style="width: 450px">
                  <el-switch
                    style="display: block"
                    v-model="detailtableDatas.status"
                    active-text="发布"
                    inactive-text="保存"
                    :active-value="Number(1)"
                    :inactive-value="Number(0)"
                  >
                  </el-switch>
                </div>
              </el-form-item>

              <div style="text-align: right; margin-top: 30px">
                <el-button @click="changeBox = false">取 消</el-button>
                <el-button type="primary" @click="changeConfirm"
                  >确认</el-button
                >
              </div>
            </el-form>
          </el-dialog>
        </div>
      </el-tab-pane>
      <!-- 修改密码 -->
      <el-tab-pane label="修改密码" name="changePassword">
        <el-form
          ref="form"
          :model="user"
          :rules="rules"
          label-width="80px"
          class="password"
        >
          <el-form-item label="原密码" prop="oldPassword">
            <el-input v-model="user.oldPassword" placeholder="请输入旧密码" type="password"/>
          </el-form-item>
          <el-form-item label="新密码" prop="newPassword">
            <el-input v-model="user.newPassword" placeholder="请输入新密码" type="password"/>
          </el-form-item>
          <el-form-item label="确认密码" prop="confirmPassword">
            <el-input v-model="user.confirmPassword" placeholder="请确认密码" type="password"/>
          </el-form-item>
          <el-form-item>
            <div>
              <el-button type="primary" size="mini" @click="submit" >确认更改</el-button>
            </div>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="子账号" name="childAccount"></el-tab-pane>
      <!-- 个人信息 -->
      <el-tab-pane label="个人信息" name="personalInfor">
        <div class="per" style="margin-top: 75px">
          <el-form
            :rules="personalRules"
            v-loading="dialogLoading"
            :model="personalList"
            label-width="120px"
            style="padding: 0 50px"
          >
            <el-form-item label="用户头像">
              <el-upload
                class="avatar-uploader"
                ref="minUpload"
                :action="action"
                accept=".jpg/.png"
                list-type="picture"
                :show-file-list=false
                :file-list='piclist'
                :on-change="imgPreview"
                :on-success="uploadSuccess"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :before-upload="beforeAvatarUpload">
                <div>
                  <img v-show="add_fileUrl?add_fileUrl:imgPath" :src="add_fileUrl?add_fileUrl:imgPath" class="avatar" width='130px' height="130px">
                  <!-- <i class="el-icon-plus avatar-uploader-icon"></i> -->
                </div>
                <!-- <p>上传图片</p>
                <div>只支持.jpg格式</div> -->
              </el-upload>
            </el-form-item>
            <el-form-item label="用户昵称">
              <el-input class="uu" v-model="personalList.userName" @blur="editNickname"></el-input
              ><el-button type="text" icon="el-icon-edit"></el-button>
            </el-form-item>
            <el-form-item label="用户手机" prop="phone">
              <el-input class="uu" v-model="personalList.userPhone" @blur="editphoneNum"></el-input
              ><el-button type="text" icon="el-icon-edit"></el-button>
            </el-form-item>
            <el-form-item label="用户邮箱">
              <span>{{ personalList.userMail }}</span>
            </el-form-item>
            <el-form-item label="微信绑定">
              <div v-if="personalList.isBinding == 0">
                 <el-button
                   size="mini"
                   type="primary"
                   @click="unboundClick"
                   icon="el-icon-connection"
                   style="margin-left: 4px; margin-right: 8px"
                >未绑定</el-button>
              </div>
              <div v-if="personalList.isBinding == 1">
                <el-button type="primary" size="mini" @click="isboundClick">已绑定</el-button>
                <span>推送每日店铺数据
                  <el-switch
                    v-model="personalList.isPushData"
                    :active-value="Number(1)"
                    :inactive-value="Number(0)"
                    @change="changeStoreData"
                  ></el-switch>
                </span>
              </div>
            </el-form-item>
            <el-form-item label="最后登录">
              <span>{{ personalList.lastLoginTime }}</span>
            </el-form-item>
            <el-form-item label="登录次数">
              <span>{{ personalList.loginSum }}</span>
            </el-form-item>
            <el-form-item label="注册时间">
              <span>{{ personalList.createTime }}</span>
            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      width="15%"
      v-loading="dialogLoading"
      @close='closeDialog'
      title="打开微信扫描二维码绑定"
      :visible.sync="wechatDialogVisible"
    > 
    <div>
      <div class="shade el-icon-warning-outline" v-if="shade">
        <el-button class="refresh" @click="unboundClick" type="text">点击刷新</el-button>
      </div>
      <img :src="QrCode" alt="" width="100%">
    </div>
    </el-dialog>
  </div>
</template>

<script>
import TimeQuantum from "@/components/data";
import {
  updateUserPwd,
  listPage,
  detail,
  update,
  messageadd,
  deleteMessage,
  getPersonInfo,
  getMessagereadAll,
  updatestatus,
  editUserInfo,     // 编辑用户信息
  getBindingTicket, // 获取【绑定】二维码
  whetherScan,      // 【绑定】二维码是否扫描
  updatePushStatus,
  getmessageNUmber,
  updateHeadPic,
} from "@/api/personaldata.js";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
export default {
  components: {
    TimeQuantum,
  },
  data() {
    const equalToPassword = (rule, value, callback) => {
      if (this.user.newPassword !== value) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    };
    // 验证手机 
    let checkPhone = (rule, value, callback) => {
      let reg = /^1[345789]\d{9}$/
      if (!reg.test(value)) {
        callback(new Error('请输入11位手机号'))
      } else {
        callback()
      }
    };
    return {
      wechatDialogVisible: false,
      QrCode: "",
      shade: false,
      time: "",
      timer: null,
      ticket: "",
      personalList: {},
      title: "",
      picurl: "",
      productFileId: "",
      bigPic: false,
      imgPath: "",
      messageTypeList: [
        { value: "0", label: "警告", },
        { value: "1", label: "公告", },
      ],
      typelist: [
        { value: "0", label: "警告", },
        { value: "1", label: "公告", },
      ],
      formInline: {
        messageType: "",
        messageTitle: "",
        messageContent: "",
        status: "",
        waitingReleaseTime: "",
      },
      list: {
        messageType: "",
      },
      detailtableData: {},
      detailtableDatas: {
        messageType: "",
        messageTitle: "",
        messageContent: "",
        status: "",
      },
      changeBox: false,
      addBox: false,
      detailBox: false,
      pageInfo: {
        keyword:'',
        messageType:'',
        pageSize: 10,
        current: 1,
        beginTime: "",
        endTime: "",
      },
      total: 0,
      list: {
        status: "",
      },
      ids: [],
      idList: [],
      multipleSelection: [],
      tableData: [],
      activeName: "messageInfo",
      loading: false,
      user: {
        oldPassword: undefined,
        newPassword: undefined,
        confirmPassword: undefined,
      },
      // 表单校验
      rules: {
        oldPassword: [
          { required: true, message: "旧密码不能为空", trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "新密码不能为空", trigger: "blur" },
          {
            min: 6,
            max: 20,
            message: "长度在 6 到 20 个字符",
            trigger: "blur",
          },
        ],
        confirmPassword: [
          { required: true, message: "确认密码不能为空", trigger: "blur" },
          { required: true, validator: equalToPassword, trigger: "blur" },
        ],
      },
      // personalRules: {
      //   phone: [
      //     { message: '请输入手机号', trigger: 'blur' },
      //     { type: 'number', validator: checkPhone, message: '请输入11位有效手机号号码', trigger: 'blur' }
      //   ]
      // },
      fileRaw: "",
      add_fileUrl: "",
      dialogLoading: false,
    };
  },
  computed:{
    action(){
      return process.env.VUE_APP_API_URL+'/api/file/upload/uploadFile';
    }
  },
  created() {
    // this.getactiveName();
    this.getPersonInfo();
  },
  mounted() {
    this.getData();
  },
  methods: {
    // getactiveName(){
    //   this.activeName = this.$route.params.activeName
    // },
    beforeAvatarUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf('.')+1);
      const extension = testmsg === 'jpg';
      const extension2 = testmsg === 'png';
      if(!extension && !extension2) {
        this.$message({
          message: '上传图片只能是 jpg、png格式!',
          type: 'warning'
        });
      }
      return extension || extension2
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let data = {
            oldPassword: this.md5(this.user.oldPassword),
            newPassword: this.md5(this.user.newPassword),
          };
          updateUserPwd(data)
          .then(res=> {
            if(res.data.code == 500) {
              this.$message.error(res.data.message);
            }else {
              this.$message.success(res.data.message);
            }
          });
        }
      });
    },
    handleClick(tab, event) {
      if (tab.index == 2) {
        this.$router.push("/childAccount");
      }
    },
    //页面初始化
    async getData() {
      this.loading = true;
      let params = {
        keyword: this.pageInfo.keyword,
        messageType: this.pageInfo.messageType,
        pageSize: this.pageInfo.pageSize,
        current: this.pageInfo.current,
        beginTime: this.pageInfo.beginTime,
        endTime: this.pageInfo.endTime,
        userType: 1
      }
      try {
        const res = await listPage(params);
        if ((Object.is(res.code), 200)) {
          this.loading = false;
          this.tableData = res.data.values;
          this.total = res.data.pageInfo.total;
          this.tableData.map(function (val) {
            if (val.messageType === 1) {
              val.messageType = "公告";
            } else {
              val.messageType = "警告";
            }
          });
        } else {
          this.$message.error(res.message);
          this.loading = false;
        }
      } catch (error) {
      }
    },
    // 查询
    search(){
      this.getData();
    },
    // 状态改变
    changeStatus(id, status) {
      this.loading = true;
      let data = {
        id: id,
        messageType: status,
      };
      updatestatus(data).then((res) => {
        this.loading = false;
        if (res.data.code == 200) {
          this.$message.success(res.data.message);
          this.getData();
        } else if (res.data.code == 500) {
          this.$message.error(res.data.message);
        }
      });
    },

    // 详情
    getDetail(id) {
      this.getmessageNUmber();
      detail(id).then((res) => {
        this.title = "消息详情";
        this.detailBox = true;
        this.loading = false;
        if (res.data.code == 200) {
          this.detailtableData = res.data.data;
          if (this.detailtableData.messageType == 1) {
            this.detailtableData.messageType = "公告";
          } else if (this.detailtableData.messageType == 0) {
            this.detailtableData.messageType = "警告";
          }
        } else {
          this.loading = false;
          this.detailBox = false;
          this.$message.warning(res.data.message);
        }
      });
    },
    // 修改
    changeMessage(id) {
      detail(id).then((res) => {
        this.changeBox = true;
        if (res.data.code == 200) {
          this.detailtableDatas = res.data.data;
          // if (this.detailtableDatas.messageType == 1) {
          //   this.list.messageType = "公告";
          // } else if (this.detailtableDatas.messageType == 0) {
          //   this.list.messageType = "警告";
          // }
        } else {
          this.loading = false;
          this.changeBox = false;
          this.$message.warning(res.data.message);
        }
      });
    },
    // 修改确认
    changeConfirm() {
      update(this.detailtableDatas).then((res) => {
        if (res.data.code == 200) {
          this.$message.success(res.data.message);
          this.getData();
          this.changeBox = false;
        } else {
          this.loading = false;
          this.$message.warning(res.data.message);
        }
      });
    },
    // 新增
    add() {
      this.addBox = true;
      this.formInline = {};
    },
    //新增确认
    async messageconfirm() {
      try {
        const res = await messageadd(this.formInline);
        if ((Object.is(res.code), 200)) {
          if (res.data.code == 200) {
            this.addBox = false;
            this.$message.success(res.data.message);
            this.getData();
          } else {
            this.$message.error(res.data.message);
            this.addBox = false;
          }
        } else {
          this.$message.error(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 删除
    deleteMessage(id) {
      this.$confirm("删除该信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteMessage(id).then((res) => {
          if (res.data.code == 200) {
            this.getData();
            this.$message.success(res.data.message);
          } else {
            this.$message.error(res.data.message);
          }
        });
      });
    },
    // 批量读消息
    allread() {
      if(this.idList.length == 0) {
        this.$message.error('请选择需要已读的数据');
        return false;
      }
      let data = {
        ids: this.idList,
      };
      getMessagereadAll(data)
      .then(res=> {
        if(res.data.code == 200) {
          this.$message.success(res.data.message);
          this.getmessageNUmber();
        }else {
          this.$message.error(res.data.message);
        }
      })
    },
    getmessageNUmber() {
      getmessageNUmber()
      .then(res=> {
        if(res.data.code == 200) {
         this.$store.commit("getMessageNumber", res.data.data);
        }
      })
    },
    // table 表格多选事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.idList = [];
      this.multipleSelection.forEach(data => {
        this.idList.push(data.id);
      });
    },
    handleSizeChange(newSize) {
      this.pageInfo.pageSize = newSize;
    },
    handleCurrentChange(newCurrent) {
      this.pageInfo.current = newCurrent;
    },
    topTime(val) {
      this.pageInfo.beginTime = val.value[0];
      this.pageInfo.endTime = val.value[1] + ' 23:59:59';
    },
    //个人资料
    async getPersonInfo() {
      try {
        const res = await getPersonInfo(this.formInline);
        if ((Object.is(res.code), 200)) {
          if (res.data.code == 200) {
            this.personalList = res.data.data;
            this.imgPath = res.data.data.imgPath;
          } else {
            this.$message.error(res.data.message);
          }
        }
      } catch (error) {
      }
    },
    // 编辑用户昵称
    editNickname() {
      if(!this.personalList.userName) {
        this.$message.error('用户昵称不能为空');
        return false;
      }
      let data = {
        userName: this.personalList.userName
      }
      editUserInfo(data)
      .then(res=> {
        if(res.data.code == 500) {
          this.$message.error(res.data.message);
        }else {
          this.personalList.userName = res.data.data.userName;
          let user = JSON.parse(sessionStorage.getItem('user'))|| {}
          user.userName = res.data.data.userName;
          sessionStorage.setItem('user', JSON.stringify(user));
          this.$store.commit('setUser');
          this.$message.success(res.data.message);
        }
      })
    },
    // 编辑用户手机
    editphoneNum() {
      if(!this.personalList.userPhone) {
        this.$message.error('用户手机号不能为空');
        return false;
      }
      let phoneReg = /(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/;
      if(!(phoneReg.test(this.personalList.userPhone))) {
        this.$message.error('请输入合法的手机号');
        return false;
      }
      let data = {
        userPhone: this.personalList.userPhone
      }
      editUserInfo(data)
       .then(res=> {
         if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }else {
           this.personalList.userPhone = res.data.data.userPhone;
           this.$message.success(res.data.message);
         }
       })
    },
    // 未绑定事件
    unboundClick() {
      this.dialogLoading = true;
      getBindingTicket()
      .then(res=> {
        this.dialogLoading = false;
        if(res.data.code == 500) {
          this.$message.error(res.data.message);
        }else {
          this.QrCode = `https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=${res.data.data}`;
          this.ticket = res.data.data;
          this.shade = false;
          this.time = new Date().getTime();
          this.whetherScan()
        }
      })
      this.wechatDialogVisible = true;
    },
    whetherScan() {
      var current = new Date().getTime();
      const num = current - this.time;
      if(num>1000*60*5 || this.shade){
        this.shade = true;
        this.time = new Date().getTime();
        return;
      }
      whetherScan({ticket:this.ticket}).then(res=>{
        if(res.data.code == 500){
          this.$message.error(res.data.message);
        }
        // var token = window.sessionStorage.getItem('token')||''
        // if(token) return 
        if(res.data.code != 200){
          this.shade = true
          return
        }
        if(res.data.code == 200) {
          if(res.data.data == true) {
            this.$message.success('二维码绑定成功');
            this.wechatDialogVisible = false;
            this.getPersonInfo();   // 更新数据
          }
        }
        if(res.data.code == 200){
          this.timer = setTimeout(()=>{
            this.whetherScan()
            clearTimeout(this.timer)
          },3000)
        }
      })
    },
    isboundClick() {
      let data = {
        isBinding: 0
      }
      editUserInfo(data)
      .then(res=> {
        if(res.data.code == 500) {
          this.$message.error(res.data.message);
        }else {
          this.personalList.isBinding = res.data.data.isBinding;
        }
      })
    },
    changeStoreData(val) {
      let data = {
        isPushData: val
      }
      updatePushStatus(data)
      .then(res=> {
        if(res.data.code == 500) {
          this.$message.error(res.data.message);
        }else {
          this.$message.success(res.data.message);
        }
      })
    },
    // 弹框关闭清除定时器
    closeDialog() {
      clearTimeout(this.timer);
    },
    async imgPreview(file, fileList) {
      let fileName = file.name;
      this.fileRaw = file.raw;
      let regex = /(.jpg|.jpeg|.gif|.png|.bmp)$/;
      if (regex.test(fileName.toLowerCase())) {
        this.picurl = URL.createObjectURL(file.raw)  // 获取URL
      }
      this.add_fileUrl = await getBase64(this.fileRaw);
    },
    uploadSuccess(res, file, fileList){
      if(res.code==200){
        this.picurl = file.url;
        console.log(res.data.fileId);
        this.getUpdateHeadPic(res.data.fileId);
        this.uploadUserPic(res.data.fileId);
        this.productFileId = res.data.fileId;
        this.$message.success(res.message);
      }else{
        this.$message.error(res.message);
      }
    },
    // 根据fileId 获取右上方头像
    getUpdateHeadPic(fileId) {
      let data = {
        headImgId: fileId
      }
      updateHeadPic(data)
       .then(res=> {
         console.log(res.data.data);
         if(res.data.code == 200) {
           let user = JSON.parse(sessionStorage.getItem('user'))||{};
           user.headUrl = res.data.data;
           sessionStorage.setItem('user', JSON.stringify(user));
           this.$store.commit("setUser");
         }
       })
    },
    uploadUserPic(fileId) {
      let data = {
        headImgId: fileId
      }
      editUserInfo(data)
       .then(res=> {
         console.log('操作成功');
       })
    },
  },
};
</script>

<style lang='scss' scoped>
.password {
  width: 500px;
  margin-top: 100px;
  margin-left: 50px;
}
.el-pagination {
  padding-top: 40px;
  text-align: center;
  margin-bottom: 10px;
}
.el-select {
  margin-right: 20px;
  margin-top: 30px;
  width: 220px;
}
.el-input {
  width: 220px;
  margin-right: 20px;
}
::v-deep .uu .el-input__inner {
  border: 0px;
}
.shade{
  background:rgba(0,0,0,.9);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  color: orange;
  text-align:center;
  line-height: 250px;
  font-size: 100px;
  .refresh{
    position: absolute;
    color: white;
    top: 50%;
    left: 40%;
    font-size: 20px;
  }
}
.avatar-uploader{
  font-size: 12px;
  border: 1px dashed #DCDFE6;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  i{
      color: #898989;
      font-size: 20px;
  }
}
</style>